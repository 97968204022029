import crossFetch from "cross-fetch";
import { Action, MiddlewareAPI } from "redux";

export const API_CALL_REQUEST = "API_CALL_REQUEST";
export const API_CALL_SUCCESS = "API_CALL_SUCCESS";
export const API_CALL_ERROR = "API_CALL_ERROR";
export const API_CALL_EXCEPTION = "API_CALL_EXCEPTION";
export const API_CALL_TIMING = "API_CALL_TIMING";

export interface IApiCallbacks {
    request?: () => void;
    success?: (response) => void;
    error?: (error: string) => void;
    exception?: (exception: Error) => void;
    finally?: () => void;
}

export interface IApiError<T> {
    error: string;
    response: T;
}

export interface IApiCallSuccess extends Action<string> {
    url: string;
    description: string;
    payload: any;
    time: number;
}

export interface IApiCallError extends Action<string> {
    url: string;
    httpCode: number;
    description: string;
    payload: IApiError<string>;
    time: number;
}

export interface IApiCallTiming extends Action {
    url: string;
    time: number;
}

export const createFetchInstance = (store: MiddlewareAPI) => {
    const handleSuccess = (json, callbacks: IApiCallbacks) => {
        if (callbacks) {
            if (callbacks.success) {
                callbacks.success(json);
            }
            if (callbacks.finally) {
                callbacks.finally();
            }
        }
    };

    const handleError = (error, callbacks: IApiCallbacks) => {
        if (callbacks) {
            if (callbacks.error) {
                callbacks.error(error.response ? error.response : error);
            }
            if (callbacks.finally) {
                callbacks.finally();
            }
        }
    };

    const handleException = (exception, callbacks: IApiCallbacks) => {
        if (callbacks) {
            if (callbacks.exception) {
                callbacks.exception(exception);
            }
            if (callbacks.finally) {
                callbacks.finally();
            }
        }
    };

    const customFetch = (
        url: string,
        init?: RequestInit
    ): Promise<Response> => {
        const timestamp: number = performance.now();

        const state = store.getState();
        if (state && state.user) {
            init.headers = {
                ...init.headers,
                // ingress-nginx doesn't like underscores in headers
                "csrf-token": state.user.csrf_token,
            };
        }

        store.dispatch({
            type: API_CALL_REQUEST,
            url,
            payload: init,
        });

        return crossFetch(url, init)
            .then((res) => {
                const error: boolean = res.status !== 200;
                const responseTime = performance.now() - timestamp;

                store.dispatch({
                    type: API_CALL_TIMING,
                    url,
                    time: responseTime,
                });

                if (error) {
                    store.dispatch({
                        type: API_CALL_ERROR,
                        url,
                        httpCode: res.status,
                        description: res.statusText,
                        payload: res,
                        time: responseTime,
                    });
                } else {
                    store.dispatch({
                        type: API_CALL_SUCCESS,
                        url,
                        description: res.statusText,
                        payload: res,
                        time: responseTime,
                    });
                }

                return res;
            })
            .catch((error: Response) => {
                const responseTime = performance.now() - timestamp;

                store.dispatch({
                    type: API_CALL_EXCEPTION,
                    url,
                    description: error,
                    time: responseTime,
                });

                throw error;
            });
    };

    return {
        fetch: customFetch,
        apiCall: (
            url: string,
            data?: any,
            callbacks?: IApiCallbacks,
            options: RequestInit = { method: "POST" }
        ) => {
            Object.assign(options, { credentials: "same-origin" });

            if (!options.headers) {
                options.headers = {};
            }

            if (data) {
                if (data instanceof Object) {
                    data = JSON.stringify(data);
                }
                options.body = data;
            }

            const promise = customFetch(url, options)
                .then((res) => {
                    if (res.status !== 200) {
                        return res
                            .json()
                            .then((json) => handleError(json, callbacks));
                    } else {
                        return res
                            .json()
                            .then((json) => handleSuccess(json, callbacks));
                    }
                })
                .catch((err) => handleException(err, callbacks));

            if (callbacks && callbacks.request) {
                callbacks.request();
            }

            return promise;
        },
    };
};
